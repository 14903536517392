.challenge-result-table-container {
  border-radius: 12px;
  background: #151B29;
  padding: 2rem 1.2rem;
  max-width: 60rem;
  width: 100%;

  .table-heading-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #272D4E;
    padding-bottom: 1rem;

    @include desktop-only {
      padding: 0 0.7rem;
      padding-bottom: 2rem;
    }

    .heading {
      color: #CBCFE6;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .table-data-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2.4rem;
    padding: 1.2rem 0.6rem;

    @include desktop-only {
      padding: 2.4rem 2.7rem;
      padding-bottom: 0;
    }

    .rank,
    .name {
      color: #FFF;
      font-size: 1.4rem;
      font-weight: 500;
      word-spacing: normal;
      text-transform: capitalize;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include desktop-only {
        width: 10rem;
      }
    }
  }
}