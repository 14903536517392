@mixin tag {
  font-weight: 400;
  font-size: 1.4rem;
  border-radius: 4rem;
  line-height: 13px;
  color: #FFFFFF;
  padding: 8px 20px;
  margin-right: 1.6rem;
  text-transform: capitalize;
}

.space-post-detail-icon {
  border-radius: 50%;
  flex: 0 0 auto;
  height: 3.2rem;
  width: 3.2rem;

  @include desktop-only {
    height: 4.8rem;
    width: 4.8rem;
  }
}

.post-detail-container {
  ::-webkit-scrollbar {
    display: none;
  }

  .space-post-detail-header {
    padding: 1.4rem 2.2rem;
    background-color: #1C2537;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include desktop-only {
      background-color: transparent;
    }

    .post-detail-header-left {
      display: flex;
      align-items: center;

      .post-name-time {
        margin-left: 1.6rem;

        .post-name {
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 120%;
          color: #FFFFFF;

          @include desktop-only {
            font-weight: 600;
            font-size: 2rem;
            line-height: 126%;

          }
        }

        .time-elapsed {
          display: none;

          @include desktop-only {
            display: block;
            font-weight: 400;
            font-size: 1rem;
            line-height: 126%;
            color: #AAAAB0;
          }
        }
      }
    }

    .post-detail-header-right {
      display: flex;
      align-items: center;

      .tag-active {
        @include tag;
        background-color: #007E51;
        margin-right: 0;
      }

      .tag-inactive {
        background-color: #E33342;
        @include tag;
        margin-right: 0;
      }

      .drop-down-menu {
        .rs-dropdown-menu {
          background-color: #0F141F;
          width: 10rem;
          margin-top: 2rem;
          margin-right: 1rem;

          .rs-dropdown-item-content {
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 124%;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  .post-detail-image-container {
    margin-top: 0;

    @include desktop-only {
      margin-top: 1.6rem;
    }

    .image-container {
      height: 23rem;

      @include desktop-only {
        height: 48.1rem;
      }
    }

    .thumbnail {
      width: 100%;
      height: 20rem;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;

      @include desktop-only {
        border-radius: 1rem;
        border: 1px solid #c4c4c4;
        height: 50rem;
      }

      .no-image-comtainer {
        display: flex;
        flex-direction: column;
        align-items: center;



        span {
          font-size: 1.2rem;
          color: #c4c4c4;
          margin-top: 1.6rem;
        }
      }
    }
  }

  .like-comment-caption-wrapper {
    padding: 0 1.6rem 1.6rem 1.6rem;

    @include desktop-only {
      padding: 0;
    }

    .like-comment-container {
      display: flex;
      margin-bottom: 1.2rem;
      margin-top: 1.2rem;

      .like-comment {
        display: flex;
        align-items: center;

        .like-comment-text {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #ffffff;
          margin-left: 4px;
        }
      }
    }

    .post-detail-caption {
      margin-top: 1.2rem;
      font-weight: 400;
      font-size: 14px;
      line-height: 124%;
      letter-spacing: 0.5px;
      color: #FFFFFF;
      word-break: break-all;
    }
  }
}

.space-post-detail {
  margin-top: -2rem;

  @include desktop-only {
    margin-top: 0rem;
  }
}