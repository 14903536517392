.challenge-detail-container-page {
  gap: 3rem;
  display: grid;

  .result-button-container {
    display: flex;
    justify-content: flex-end;
  }

  .result-container {
    .sub-heading {
      color: #EEF0F7;
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 1.6rem;
    }
  }

  .btn {
    padding: 0.8rem 2rem;
    margin: 0;

    @include desktop-only {
      padding: 1.2rem 2.4rem;
    }
  }
}