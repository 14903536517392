.short-detail-wrapper {
  flex: 0 0 auto;
  width: 100%;

  .icon-name-time {
    display: flex;
    align-items: center;
    padding: 1.4rem 0;

    @include desktop-only {
      padding: 1.4rem 2.2rem;
    }

    .name-time {
      margin-left: 1.6rem;

      .user-name {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 120%;
        color: #FFFFFF;

        @include desktop-only {
          font-weight: 600;
          font-size: 2rem;
          line-height: 126%;
        }
      }

      .publish-time {
        font-weight: 400;
        font-size: 1rem;
        color: #FFFFFF;

        @include desktop-only {
          font-weight: 400;
          font-size: 1rem;
        }
      }
    }
  }

  .like-comment-wrapper {
    display: flex;
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;

    .like-comment {
      align-items: center;
      display: flex;
    }

    .like-comment-text {
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      margin-left: 4px;
    }
  }

  .short-caption {
    margin-top: 1.2rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 124%;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    word-break: break-all;
  }
}