.playoff-round-card-wrapper {
  background-color: #1C2537;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 4.5rem;
  border: 1px solid transparent;


  @include desktop-only {
    &:hover {
      border: 1px solid #5764AD;
      box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.2);
      transition: all ease .3s;
    }
  }

  &.moderator:hover {
    box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.2);
    border: 1px solid #E33342;
    transition: all ease .3s;
  }

  .playoff-round-img-wrapper {
    position: relative;

    .playoff-round-img {
      width: 100%;
      height: 16rem;
      object-fit: cover;
    }

    .playoff-round-super-moderator-symbol {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(-1.5rem);
    }
  }

  .playoff-round-detail {
    padding: 2.4rem 1.6rem;

    .round-name {
      font-weight: 600;
      font-size: 1.3rem;
      letter-spacing: 0.5px;
      color: #5764AD;
    }

    .playoff-round-name {
      margin-top: 1.2rem;
      word-break: break-word;
      font-weight: 500;
      font-size: 1.6rem;
      letter-spacing: 0.3px;
      color: #FFFFFF;
    }

    .team-groups {
      .team-groups-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.6rem;


        .team-groups-icon {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .icon {
            margin-right: 8px;
          }
        }

        .team-groups-text {
          font-weight: 400;
          font-size: 13px;
          line-height: 13px;
          color: #FFFFFF;
        }
      }
    }
  }
}