@mixin font-light {
  font-weight: 300;
}

@mixin font-regular {
  font-weight: 400;
}

@mixin font-semi-bold {
  font-weight: 600;
}

@mixin font-bold {
  font-weight: 700;
}

@mixin desktop-only {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin tab-only {
  @media (max-width: 1199px) and (min-width: 542px) {
    @content;
  }
}

@mixin mobile-only {
  @media (max-width: 541px) and (min-width: 10px) {
    @content;
  }
}