.short-comment-card {
  padding: 1.2rem;
  background: #1E233D;
  border-radius: 16px;
  display: flex;
  margin-bottom: 8px;

  .comment-icon {
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    background-color: #ffffff;
    flex: 0 0 auto;
    color: #1b1952;
  }

  .commenter-name {
    margin-left: 1.2rem;

    .comment-post-by {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 124%;
      color: #da4d4d;
    }

    .short-comment {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 124%;
      color: #FFFFFF;
      word-break: break-all;
    }

    .comment-options-container {
      margin-top: 1.4rem;
      display: flex;
      align-items: center;

      .dot {
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        overflow: hidden;
        background-color: #848CA2;
        margin: 0 0.5rem;
      }

      .comment-options {
        font-weight: 600;
        font-size: 10px;
        line-height: 124%;
        letter-spacing: -0.01em;
        color: #848CA2;
        background-color: transparent;
      }

      .show-reply {
        font-weight: 400;
        font-size: 10px;
        line-height: 124%;
        text-decoration-line: underline;
        color: #848CA2;
        background-color: transparent;
      }

    }
  }
}

.reply-container {
  margin-left: 5.7rem;

  @include desktop-only {
    margin-left: 7rem;

  }
}