.challenge-card {
  border-radius: 20px;
  border: 1px solid rgba(110, 106, 240, 0.20);
  background: #1C2537;
  height: 31.1rem;
  flex-shrink: 0;
  padding-bottom: 2.2rem;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.20);

    .challenge-detail {
      border-bottom: 1px solid #6E6AF0;
      background: rgba(110, 106, 240, 0.1);

      .quotes {
        background: #FFF;
        background-clip: text;
      }
    }

    .date-time-wrapper {
      .date {
        color: #FFF;
      }
    }
  }

  .challenge-detail {
    padding: 0 1.6rem;
    padding-top: 1.1rem;
    border-radius: 20px 20px 180px 180px;
    border-bottom: 1px solid rgba(110, 106, 240, 0.60);
    background: rgba(110, 106, 240, 0.05);
    padding-bottom: 4.4rem;
    position: relative;

    .challenge-id-status-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.8rem;
      align-items: center;

      .challenge-id {
        height: 100%;
        color: #FFF;
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 0.5px;
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.10);
        padding: 2px 6px;
        text-transform: capitalize;
      }

      .status {
        color: #FFF;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.5px;
        border-radius: 4rem;
        padding: 0.8rem 1.6rem;
        line-height: 13px;
      }
    }

    .quotes {
      font-size: 1.6rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-align: center;
      background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.20) 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 13.3rem;
      word-break: keep-all;
      line-height: 160%;
      word-break: break-all;
    }

    .image-wrapper {
      position: absolute;
      width: 5.2rem;
      height: 5.2rem;
      border: 2px solid #6E6AF0;
      bottom: -12%;
      border-radius: 100%;
      background-color: #FFF;
      overflow: hidden;
      left: 50%;
      transform: translate(-50%, -10%);

      .challenge-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .date-time-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 5.3rem;

    .date {
      color: #CBCFE6;
      font-size: 1.2rem;
      font-weight: 400;
      letter-spacing: 0.5px;
    }

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 14rem;
    }
  }
}