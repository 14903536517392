.uploader-conatiner {
  width: 100%;
  height: 100%;

  .uploader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.1s linear;
    border: 0.5px solid rgba(87, 100, 173, 0.35);
    border-radius: 6px;

    &.error {
      border-color: red;
    }

    p {
      margin-top: 1rem;
    }
  }

  .uploader-preview {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 1rem;

    .label-overlay {
      position: absolute;
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
      top: 1rem;
      left: 1rem;
      padding: 1rem;
      border-radius: 6px;
      transition: all 0.2s ease-in;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 1.8rem;
      background-color: rgba(#000000, 0.4);
      @include font-semi-bold;
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }

    .close-icon {
      position: absolute;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background-color: red;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0rem;
      top: 0;
      cursor: pointer;

      h6 {
        color: #414B82;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }
}