.reply-card-wrapper {
  padding: 1.2rem;
  background: #1E233D;
  border-radius: 16px;
  display: flex;
  margin-bottom: 8px;
  background-color: #111423;

  .reply-card-icon {
    width: 3.3rem;
    height: 3.3rem;
    flex: 0 0 auto;
    border-radius: 50%;
  }

  .reply-card-comment {
    margin-left: 1.2rem;

    .reply-posted-by {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 124%;
      color: #FFFFFF;
    }

    .reply-posted {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 124%;
      color: #FFFFFF;
    }

    .reply-options-container {
      display: flex;
      align-items: center;
      margin-top: 1.4rem;

      .reply-options {
        font-weight: 500;
        font-size: 1rem;
        line-height: 124%;
        color: #848CA2;
        background-color: transparent;
      }

      .dot {
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        overflow: hidden;
        background-color: #848CA2;
        margin: 0 0.5rem;
      }

      .show-reply {
        font-size: 1rem;
        color: #1b1952;
        text-decoration: underline;
      }
    }
  }
}