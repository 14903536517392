.delete-text {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 20px;
  color: #FFFFFF;

  @include desktop-only {
    font-size: 2rem;
  }
}

.delete-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 4.9rem;

  .delete-btn {
    background-color: rgba(110, 106, 240, 0.35) !important;
    margin-right: 1.6rem;
  }
}