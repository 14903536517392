.match-schedule-card {
  width: 100%;
  margin-bottom: 1.2rem;
  overflow: hidden;
  border-radius: 8px;
  padding: 1.6rem;
  cursor: pointer;
  background-color: #1C2537;
  border: 1px solid transparent;


  @include desktop-only {
    &:hover {
      border: 1px solid #5764AD;
      box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.2);
      transition: all ease .3s;
    }

    &.status:hover {
      box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.2);
      border: 1px solid #33ba38a6;
      transition: all ease .3s;
    }
  }

  .key-value {
    display: grid;
    grid-template-columns: 11rem auto;
    margin-bottom: 1rem;
    width: 100%;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .key {
    font-size: 1.5rem;
    color: #5764AD;
    font-weight: 700;
  }

  .value {
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: left;
  }

  .live {
    background-color: #2196f3;
    padding: 0 1rem;
    border-radius: 4rem;
  }

  .completed {
    background-color: #33ba38a6;
    padding: 0 1rem;
    border-radius: 4rem;
  }

  .time {
    cursor: pointer;
    color: red;
  }
}

.card-disable {
  cursor: not-allowed;
}