.btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2.4rem;

  .dv-create-btn {
    display: none;

    @include desktop-only {
      display: block;
    }
  }

  .dv-short-btn {
    margin-right: 1rem;
    padding: 0.8rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 1.2rem 1.6rem;

    @include desktop-only {
      padding: 1.2rem 2.4rem;
      font-size: 1.4rem;
      color: #ffffff;
    }
  }
}

.space-detail-banner {
  object-fit: cover;
  width: 100%;
  position: relative;
  height: 26.5rem;

  @include desktop-only {
    height: 24rem;
  }
}

.space-icon-wrapper {
  position: absolute;
  height: 11.2rem;
  width: 11.2rem;
  border-radius: 50%;
  border: 2.11321px solid #6E6AF0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #202020;
  transform: translateY(-60%);
  left: 3rem;

  @include desktop-only {
    left: 0;
    background-color: #202020;
    transform: translate(16%, -20%);
  }

  .space-detail-icon {
    height: 9.5rem;
    width: 9.5rem;
    border-radius: 50%;
    overflow: hidden;
  }
}

.name-description {
  margin-top: 5.7rem;
  padding: 0 2.4rem;

  @include desktop-only {
    padding-left: 15rem;
    margin-top: 1.6rem;
  }

  .space-detail-name {
    font-weight: 500;
    color: #FFFFFF;
    font-size: 1.8rem;

    @include desktop-only {
      margin: 0;
      line-height: 120%;
      font-size: 2.2rem;
    }
  }

  .space-description {
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.1rem;
    height: 3rem;
    line-height: 120%;

    @include desktop-only {
      height: 3.5rem;
      font-size: 1.4rem;
    }
  }
}