.view-rewards-data {
  &.rs-table {
    background-color: rgba(39, 45, 78, 0.1);

    .rs-table-scrollbar-vertical {
      display: none;
    }

    .rs-table-row {
      background-color: rgba(39, 45, 78, 0.1);
      border-bottom: none;
      background-color: transparent;

      &:hover {
        .rs-table-cell {
          background: transparent;
        }
      }

      &.rs-table-row-header {
        &:hover {
          background-color: inherit;
        }
      }

      &:hover:not(.rs-table-row-header) {
        background: #525298;
        transition: background-color 0.1s none;
      }

      &.rs-table-row-header {
        height: 4.7rem !important;
        padding: 0 !important;

        .rs-table-cell {
          background-color: transparent !important;

          .rs-table-cell-content {
            font-weight: 500;
            color: #EEF0F7;
            padding: 1.2rem 1.6rem;
            background-color: transparent;
            font-size: 1.1rem;

            @include desktop-only {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }

  .view-rewards-value {

    &.rs-table-cell {
      background: transparent;

      &:hover {
        background: transparent;
      }

      .rs-table-cell-content {
        background-color: transparent;
        font-weight: 400;
        color: #FFFFFF;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        font-size: 1.1rem;
        text-transform: capitalize;

        @include desktop-only {
          font-size: 1.4rem;
        }

        .rs-table-cell-wrap {
          height: 100%;
        }
      }
    }
  }
}