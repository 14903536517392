.input {
  border-radius: 0.6rem;
  background-color: #0F141F;
  height: 3.6rem;
  font-size:1.4rem;
  margin-bottom: 1rem;

  &:hover,
  &:focus {
    border-color: rgba(87, 100, 173, 0.35);
  }

  &::placeholder {
    color: #414B82;
    font-weight: 600;
  }
}

.create-post-label{
margin: 0.4rem 0.2rem;
color:rgb(52, 60, 104);
font-weight: 600;
display: block;
}

.select-container {
  height: 3.6rem;
  margin-bottom: 1rem;
}

.upload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24rem;

  @include desktop-only {
    margin-top: 2.4rem;
    height: 36.5rem;
  }

  :hover {
    background-color: transparent;
  }
}

.create-post-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;
}