.challenge-result-form-container {
  border-radius: 1.2rem;
  background: #0F141F;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .table-content {
    height: calc(100vh - 27rem);
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .table-header {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 3.2rem 5.4rem;
    border-bottom: 1px solid #272D4E;
    background: #0F141F;
    gap: 5.2rem;

    .table-heading {
      color: #CBCFE6;
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: -0.16px;
      white-space: nowrap;
    }
  }

  .table-data {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5.2rem;
    padding: 3.1rem 5.4rem;
    align-items: center;
    border-bottom: 1px solid rgba(39, 45, 78, 0.40);

    &:last-child {
      border-bottom: none;
    }

    .table-value {
      color: #fff;
      font-size: 1.8rem;
      font-weight: 500;
    }


    .select {
      margin-bottom: 0 !important;
    }

    .reject {
      color: #B93B48;
      font-size: 1.4rem;
      font-weight: 400;
      text-decoration-line: underline;
      cursor: pointer;
      line-height: 120%;
    }



    .screen-shot {
      width: 5.4rem;
      height: 5.4rem;
      flex-shrink: 0;
      border-radius: 6px;
      background: #D9D9D9;
      cursor: pointer;
      overflow: hidden;
      object-fit: cover;

      .image {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.rejection-prompt {
  border-top: 1px solid #272D4E;
  padding-top: 3.2rem;

  .rejection-button-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 2.4rem;

    .rejection-button {
      padding: 1.2rem 2rem;
    }
  }
}

.verification-screen-shot {
  overflow: hidden;
  border-radius: 1rem;

  .screen-shot {
    width: 100%;
    height: 100%;
  }
}