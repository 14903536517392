.tournament-card-wrapper {
  background-color: #1C2537;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid transparent;


  @include desktop-only {
    &:hover {
      border: 1px solid #5764AD;
      box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.2);
      transition: all ease .3s;
    }

    &.moderator:hover {
      box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.2);
      border: 1px solid #E33342;
      transition: all ease .3s;
    }
  }

  .tournament-img-wrapper {
    position: relative;

    .tournament-card-img {
      width: 100%;
      height: 16rem;
      object-fit: cover;
    }

    .tournament-status {
      position: absolute;
      top: 1.5rem;
      left: 1.4rem;
      border-radius: 4rem;
      padding: 6px 16px;
      line-height: 13px;
      letter-spacing: 0.5px;
      font-size: 1.1rem;

      @include desktop-only {
        font-size: 1.4rem;
        padding: 8px 16px;
      }
    }

    .super-moderator-symbol {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(-2rem);
    }

    .tournament-icon {
      width: 5rem;
      height: 5rem;
      position: absolute;
      overflow: hidden;
      border-radius: 100%;
      right: 0;
      top: 13.5rem;
      transform: translateX(-3rem);
    }
  }

  .tournament-detail {
    padding: 1.6rem;

    @include desktop-only {
      padding: 2.4rem 1.6rem;
    }

    .space-name {
      font-weight: 600;
      font-size: 1.4rem;
      letter-spacing: 0.5px;
      color: #5764AD;
    }

    .tournament-title {
      margin-top: 1.2rem;
      font-weight: 500;
      font-size: 1.8rem;
      letter-spacing: 0.3px;
      color: #FFFFFF;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .tournament-type-date {
      display: flex;
      margin-top: 1.6rem;
      align-items: center;

      .type-date {
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.5px;
        color: #808191;
      }

      .dot {
        height: 4px;
        width: 4px;
        border-radius: 100%;
        background-color: #808191;
        margin: 0 1rem;
      }
    }
  }
}