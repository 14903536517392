.playoff-container {
  margin-bottom: 1.6rem;

  @include desktop-only {
    margin-bottom: 2.4rem;
  }

  .playoff-title-button-container {
    display: none;

    @include desktop-only {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }


    .playoff-title {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: #FFFFFF;
    }

    .playoff-button {
      margin-right: 2.4rem;
    }
  }

  .tournament-status-button {
    display: flex;
    margin-top: 2rem;
    justify-content: space-between;

    @media (min-width:540px) {
      display: none;
    }

    .tournament-status-text {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 120%;
      color: #FFFFFF;
    }

    .tournament-status-text-inactive {
      font-weight: 500;
      font-size: 11px;
      line-height: 120%;
      color: #FFFFFF;
    }
  }
}

.mv-playoff-title {
  font-weight: 600;
  line-height: 40px;
  color: #FFFFFF;
  font-size: 1.8rem;

  @include desktop-only {
    display: none;
  }
}

.dummy-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7rem;

  .dummy {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dummy-text {
      margin-top: 3.2rem;
      margin-bottom: 1rem;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #B7B5C6;

      @include desktop-only {
        font-size: 2.4rem;
        margin-bottom: 2.4rem;
      }
    }

    .dummy-content {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 28px;
      letter-spacing: 0.02em;
      color: #B7B5C6;

      @include desktop-only {
        font-size: 2rem;
      }
    }
  }
}