.round-result-container {
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 7rem;

  .match-result-subhead {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 120%;
    color: #FFFFFF;
    margin-bottom: 1.6rem;
    padding: 0 1.4rem;

    @include desktop-only {
      font-weight: 700;
      font-size: 2.4rem;
      padding: 0;
      line-height: 23px;
    }
  }

  .match-result-head {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 120%;
    color: #FFFFFF;
    padding: 0 1.4rem;
    margin-bottom: 1.6rem;

    @include desktop-only {
      font-weight: 500;
      font-size: 2rem;
      line-height: 23px;
      color: #EEF0F7;
    }
  }

  .match-result-data {
    &.rs-table {
      background-color: rgba(39, 45, 78, 0.1);

      .rs-table-scrollbar-vertical {
        display: none;
      }

      .rs-table-row {
        background-color: rgba(39, 45, 78, 0.1);
        border-bottom: none;
        background-color: transparent;

        &.rs-table-row-header {
          &:hover {
            background-color: inherit;
          }
        }

        @include desktop-only {
          &:hover:not(.rs-table-row-header) {
            background-color: #525298;
            transition: background-color 0.1s none;
          }
        }

        &.rs-table-row-header {
          height: 4.7rem !important;
          padding: 0 !important;

          .rs-table-cell {
            background-color: transparent;


            .rs-table-cell-content {
              font-weight: 500;
              font-size: 1.1rem;
              color: #EEF0F7;
              padding: 1.2rem 1.6rem;
              background-color: transparent;
              padding-left: 1.6rem;
              padding-right: 1.6rem;

              @include desktop-only {
                font-size: 1.6rem;
              }
            }
          }
        }

      }
    }

    .match-result-value {
      &.rs-table-cell {
        background-color: transparent;

        .rs-table-cell-content {
          background-color: transparent;
          font-weight: 400;
          font-size: 1.1rem;
          color: #FFFFFF;
          padding-left: 1.6rem;
          padding-right: 1.6rem;
          text-transform: capitalize;

          @include desktop-only {
            font-size: 1.4rem;
          }

          .rs-table-cell-wrap {
            height: 100%;
          }
        }
      }
    }

    .rs-btn {
      background-color: transparent;
      padding: 0;

      &.rs-btn-primary {
        font-size: 1.1rem;
        color: #FFFFFF;

        @include desktop-only {
          font-size: 1.4rem;
        }
      }
    }
  }
}

.remove-padding {
  margin: 0 -23px;
}