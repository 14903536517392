.challenge-detail-container {
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.6rem;

  @include desktop-only {
    background: #151B29;
    padding: 1.6rem 2rem;
    gap: 2.4rem;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  .challenge-image {
    height: 18.3rem;
    border-radius: 1.2rem;
    flex: 0 0 auto;
    width: 100%;
    overflow: hidden;

    @include desktop-only {
      width: 25.6rem;
    }

    .image {
      width: 100%;
      height: 100%;
    }
  }


  .challenge-detail-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    width: 100%;

    @include desktop-only {
      gap: 3.3rem;
    }

    .challenge-name-wrapper {
      .name {
        color: #808191;
        font-size: 2.7rem;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 1.2rem;
        text-transform: capitalize;
      }

      .quotes {
        color: #FFF;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 140%;
        word-break: break-all;
      }
    }

    .challenge-info-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      grid-template-columns: repeat(3, 1fr);
      grid-template-columns: repeat(2, 1fr);
      display: grid;


      @include desktop-only {
        grid-template-columns: repeat(5, 1fr);
      }

      .entity-value-wrapper {
        display: flex;
        gap: 0.8rem;
        flex-direction: column;

        .entity {
          color: #808191;
          font-size: 1.4rem;
          font-weight: 400;
        }

        .value {
          color: #FFF;
          font-size: 1.4rem;
          font-weight: 600;
        }

        .completed {
          color: #0270FC;
        }

        .live {
          color: '#E33342'
        }
      }
    }
  }
}