.short-input-text {
  color: antiquewhite;
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
  opacity: 100%;

  @include desktop-only {
    font-size: 1.7rem;
    color: antiquewhite;
  }
}

.upload-container {
  border: 1px solid #343c68;
  border-radius: 0.4rem;
  padding: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 20rem;
  margin-top: 1rem;

  &:hover,
  &:focus {
    border-color: #6e6af0;
    transition: border-color ease-in-out 0.3s;
  }

  @include desktop-only {
    width: 100%;
    height: 34rem;
  }

  .upload-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;

    .upload-text {
      font-size: 1.4rem;
      color: antiquewhite;
      margin-top: 1rem;

      @include desktop-only {
        font-size: 1.7rem;
      }
    }
  }

  .upload-cross-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .upload-text {
      font-size: 1.4rem;
      color: antiquewhite;
      margin-right: 5px;

      @include desktop-only {
        font-size: 1.7rem;
      }
    }
  }
}