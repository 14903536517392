.login-container {
  width: 100%;
  max-width: 50rem;
  height: auto;

  .rs-panel {
    background-color: $color-body-background;

    .logo-wrapper {
      max-width: 35rem;
      width: 100%;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }

    .signin-conatiner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
      min-height: 10rem;
      padding: 2rem 0;
      position: relative;
    }
  }
}
