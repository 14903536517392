.empty-space-post-container {
  height: 23rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-post-card-container {
  background-color: #1C2537;
  border-radius: 2rem;
  overflow: hidden;
  cursor: pointer;

  .post-card-image-container {
    height: 23rem;
    
    .space-post-card-image {
      position: relative;
      height: 23rem;
      width: 100%;
    }
  }

  .post-status {
    position: absolute;
    top: 1rem;
    left: 1rem;
    padding: 6px 16px;

    @include desktop-only {
      font-size: 1.4rem;
      padding: 8px 16px;
    }
  }
  .post-status-tag {
    border-radius: 4rem;
    padding: 6px 16px;
    line-height: 13px;
    letter-spacing: 0.5px;
    font-size: 1rem;
    text-transform: capitalize;

    @include desktop-only {
      font-size: 1.4rem;
      padding: 8px 16px;
    }
  }


  .post-caption-time-container {
    padding: 1.4rem 1.4rem 2rem 1.4rem;

    @include desktop-only {
      padding: 1.6rem 1.6rem 2.4rem 1.6rem;
    }

    .space-post-caption {
      font-weight: 400;
      color: #808191;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 120%;
      word-break: break-all;
      font-size: 1.1rem;
      height: 2.4rem;

      @include desktop-only {
        height: 3.5rem;
        font-size: 1.4rem;
      }
    }

    .space-post-created-time {
      font-weight: 400;
      color: #808191;
      margin-top: 8px;
      line-height: 126%;
      font-size: .9rem;

      @include desktop-only {
        font-size: 1rem;
      }
    }

    .space-post-by-status-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2.2rem;

      @include desktop-only {
        margin-top: 2.4rem;
      }


      .post-by-name {
        font-weight: 500;
        color: #FFFFFF;
        line-height: 126%;
        font-size: 1.1rem;

        @include desktop-only {
          font-size: 1.4rem;
        }
      }
    }
  }
}