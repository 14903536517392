.playoff-result-credential-btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2.4rem;

  .add-result-btn {
    margin-right: 1rem;
    padding: 0.8rem;
    font-size: 1.2rem;
    line-break: auto !important;

    @include desktop-only {
      margin-right: 1.4rem;
      padding: 1.2rem 2.4rem;
      font-size: 1.4rem;
    }
  }

  .btn-space {
    margin-right: 1rem;

    @include desktop-only {
      margin-right: 1.4rem;
    }
  }
}

.player-stat-modal {
  &.rs-modal {
    .rs-modal-dialog {
      .rs-modal-content {
        box-shadow: none;

        .rs-modal-body {
          margin-top: 0;
        }
      }
    }
  }
}

.team-drawer {
  .rs-drawer-content {
    overflow: hidden;
  }
}


.play-off-detail-page {
  margin: 0 -23px;
  margin-bottom: 7rem;

  @include desktop-only {
    margin: 0;
  }
}