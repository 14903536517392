.schedule-match-container {
  margin-bottom: 7rem;
  width: 100%;
  position: relative;

  .title-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.8rem;

    @include desktop-only {
      margin-bottom: 2.4rem;
    }

    .schedule-title {
      font-weight: 600;
      color: #FFFFFF;
      font-size: 1.4rem;

      @include desktop-only {
        font-size: 3.2rem;
      }
    }

    .schedule-btn {
      padding: 0.8rem;
      font-size: 1.2rem;

      @include desktop-only {
        padding: 1.2rem 2.4rem;
        font-size: 1.4rem;
      }
    }
  }

  .no-matches {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40rem;

    p {
      font-size: 1.5rem;
      font-weight: 700;
      color: #FFFFFF;

      @include desktop-only {
        font-size: 1.8rem;
        font-weight: 700;
      }
    }
  }
}


.confirmation-pop {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 2.5rem;

  @include desktop-only {
    font-size: 2rem;
  }
}

.btn-container {
  display: flex;
  justify-content: flex-end;

  .cancel-btn {
    background-color: #5764AD;
    margin-right: 1rem;
  }
}