.description-toolbar {
  width: 100%;

  .rdw-editor-toolbar {
    border-radius: 6px;
    border: 1px solid #e5e5ea;
  }

  .rdw-editor-main {
    border-radius: 6px;
    border: 1px solid #e5e5ea;
    padding: 0 1rem;
  }
}

.select-team-list {
  &.rs-list {
    -webkit-box-shadow: none !important;
    box-shadow: none;
  }

  .rs-list-item {
    background-color: #0f141f;
    border: 1px solid #343c68;
    box-shadow: none;

    &:first-child {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
}
