.playoff-card-wrapper {
  background-color: #1C2537;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;

  @include desktop-only {
    &:hover {
      border: 1px solid #5764AD;
      box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.2);
      transition: all ease .3s;
    }
  }

  .playoff-img-wrapper {
    position: relative;

    .playoff-img {
      width: 100%;
      height: 16rem;
      object-fit: cover;
    }
  }

  .playoff-detail {
    padding: 2.4rem 1.6rem;

    .round-name {
      font-weight: 600;
      font-size: 1.3rem;
      letter-spacing: 0.5px;
      color: #5764AD;
    }

    .playoff-name {
      margin-top: 1.2rem;
      word-break: break-word;
      font-weight: 500;
      font-size: 1.6rem;
      letter-spacing: 0.3px;
      color: #FFFFFF;
    }

    .team-types {
      .team-types-detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.6rem;


        .team-types-icon {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .icon {
            margin-right: 8px;
          }
        }

        .team-types-text {
          font-weight: 400;
          font-size: 13px;
          line-height: 13px;
          color: #FFFFFF;
        }
      }
    }
  }
}