html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.rs-dropdown-menu {
  background-color: #373c47;

  .rs-dropdown-item {
    .rs-dropdown-item-content {
      color: #ffffff;

      &:hover,
      &:focus {
        background-color: #6e6af0;
        color: #ffffff;
      }
    }
  }
}
