.post-detail-container {
  display: flex;
  flex-direction: column;

  @include desktop-only {
    flex-direction: row;
    border-radius: 0;
  }

  .post-left-container {
    margin: 0 -24px;
    width: auto;

    @include desktop-only {
      width: 68.3rem;
      flex: 0 0 auto;
      margin-right: 2.4rem;
      overflow-y: scroll;
    }
  }

  .v-divider {
    display: none;

    @include desktop-only {
      display: block;
      height: auto;
      border-left: 1px solid #2D3754;
    }
  }

  .post-right-container {
    width: 100%;
    position: relative;
  }
}