.playoff-round-container {
  margin-bottom: 7rem;

  .prize-distribution-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;

    .playoff-round-title {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 40px;
      color: #FFFFFF;

      @include desktop-only {
        font-size: 3.2rem;
      }
    }
  }
}

.playoff-group-title {
  font-weight: 600;
  line-height: 40px;
  color: #FFFFFF;
  font-size: 1.8rem;
  margin-bottom: 1.8rem;

  @include desktop-only {
    margin-bottom: 2.4rem;
    font-size: 3.2rem;
  }
}

.prize-distribution-button-container {
  display: flex;
  justify-content: flex-end;
}