.comment-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  padding-bottom: 10rem;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  height: fit-content;

  @include desktop-only {
    height: calc(var(--app-height) - 24rem);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .no-comments {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(var(--app-height) - 60rem);

    @include desktop-only {
      justify-content: center;
      height: calc(var(--app-height) - 24rem);
    }

    .no-comment-text {
      font-weight: 500;
      margin-top: 2rem;
      color: #ffffff;
      font-size: 1.4rem;
    }
  }
}

.comment-send-container {
  position: fixed;
  bottom: 6rem;
  width: calc(100% + 1rem);
  left: 0;

  @include desktop-only {
    width: calc(100% + 1rem);
    left: -10px;
    position: absolute;
  }

  .mention-user-container {
    bottom: 6.5rem;
    left: 1rem;
    position: fixed;

    @include desktop-only {
      position: absolute;
      bottom: 0.5rem;
    }
  }

  .comment {
    border-radius: 0;
    position: absolute;
    background: linear-gradient(0deg, #0f141e, #0f141e), #131925;
    font-size: 1.4rem;
    border: none;
    color: #848ca2;
    font-weight: 600;
    padding: 2.1rem 2.4rem;

    &:hover,
    &:focus {
      border-color: #ffff;
    }

    &::placeholder {
      color: #848ca2;
      font-weight: 600;
    }
  }

  .send-btn {
    position: absolute;
    cursor: pointer;
    background-color: transparent;
    bottom: 2rem;
    top: 2rem;
    right: 2.4rem;

    @include desktop-only {
      top: 2.2rem;
    }
  }
}
