.tooltip-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 4px;

  .team-name-head {
    color: #6e6af0;
    font-size: 1.6rem;
    font-weight: 600;
    margin-right: 5px;
  }
}

.tooltip-style {
  .rs-tooltip-inner {
    max-width: none;
  }

  .in-game-name {
    font-size: 1.4rem;
    font-weight: 500;
    text-align: left;
  }
}

.team-result-container {
  width: 100%;
  overflow-x: hidden;

  .team-result-wrapper {
    width: 100%;
    padding-bottom: 1.6rem;

    &:not(:last-child) {
      border-bottom: 1px solid #6e6af0;
      margin-bottom: 1.6rem;
    }
  }

  &.rs-panel-group > .rs-panel + .rs-panel::before {
    border-top: 1px solid #343c68;
    left: 0;
    right: 0;
  }
}
