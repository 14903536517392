.team-detail-container {
  padding: 1.6rem;
  height: 100%;
  overflow: auto;

  @include desktop-only {
    overflow: auto;
    height: 100%;
    padding: 2.4rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .team-name-slot {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .team-name {
      font-size: 1.4rem;
      color: #6e6af0;
      margin-bottom: .8rem;
      margin-top: .8rem;
      font-weight: 700;

      @include desktop-only {
        font-size: 1.6rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
      }
    }
  }

  .team-details-list {
    &.rs-list-bordered {
      box-shadow: 0 0 0 1px #6e6af0;
    }

    .member-name-checked-wrapper {
      padding: 1rem .8rem;

      @include desktop-only {
        padding: 2rem;
      }

      &.rs-list-item {
        &.rs-list-item-bordered {
          box-shadow: 0 -1px 0 #6e6af0;
        }
      }

      .rs-list-item-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .member-name-wrapper {
          display: flex;
          align-items: center;

          .member-name {
            font-size: 1rem;
            color: #fff;
            margin-left: 4px;
            margin-right: 4px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 23rem;

            @include desktop-only {
              font-size: 1.6rem;
              font-weight: 500;
              margin-left: 8px;
              margin-right: 8px;
              max-width: 30rem;

            }
          }

          .team-captain {
            height: 1.4rem;
            width: 1.4rem;
            flex: 0 0 auto;

            @include desktop-only {
              height: 1.8rem;
              width: 1.8rem;
            }
          }
        }

        .checked-in {
          width: 1.8rem;
          height: 1.8rem;
          flex: 0 0 auto;

          @include desktop-only {
            width: 2.5rem;
            height: 2.5rem;
          }
        }
      }
    }
  }

  .hr-line {
    border-bottom: 1px solid #c0c1c6;
    margin: 1.6rem 0;
  }
}