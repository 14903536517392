.space-card-container {
  background-color: #1C2537;
  border: 2px solid #131925;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1.56923px solid #131925;

  @include desktop-only {
    &:hover {
      border: 1.56923px solid #6E6AF0;
    }
  }

  .space-img {
    width: 100%;
    height: 13.1rem;
    object-fit: cover;

    @include desktop-only {
      height: 18rem;
    }
  }

  .space-card-detail {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: .8rem 0 1.6rem 0;

    @include desktop-only {
      padding: 1.7rem 0;
    }

    .space-card-title {
      font-weight: 600;
      font-size: 1.6rem;
      color: #FFFFFF;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      word-wrap: break-word;
      margin-bottom: 4px;
      max-width: 10rem;

      @include desktop-only {
        max-width: 15rem;
      }
    }

    .members-container {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      .members {
        margin-left: 4px;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.4);
        font-size: 1.2rem;

        @include desktop-only {
          font-size: 1.4rem;
        }
      }
    }
  }
}