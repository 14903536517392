.ban-team-drawer {
  width: 100%;
  height: calc(100% - 5rem);
  overflow-y: auto;
}

.ban-button {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 5rem;

  .rs-btn {
    margin-left: 1.5rem;

    &:is(:hover, :focus):disabled {
      background-color: #6e6af0;
    }
  }
}
