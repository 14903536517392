.rs-container.main-container {
  height: var(--app-height);
  width: 100%;
  background-color: #0f141f;
}

.rs-header.app-topbar {
  display: flex;
  background-color: $color-primary;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 2.4rem;
  height: 6.8rem;

  @include desktop-only {
    padding: 2rem 3.2rem;
  }

  .top-bar-left-container {
    display: flex;
    align-items: center;

    .topbar-logo {
      height: 1.5rem;

      @media (max-width: 900px) {
        display: none;
      }
    }

    .menu-style {
      cursor: pointer;
      margin-top: 0.4rem;

      @media (min-width: 900px) {
        display: none;
      }
    }
  }

  .switch-role {
    margin-right: 2.4rem;

    .rs-dropdown-toggle {
      background-color: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .profile-avatar {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 100%;

    @include desktop-only {
      width: 3.2rem;
      height: 3.2rem;
    }

    .rs-avatar-image {
      width: 2.8rem;
      height: 2.8rem;

      @include desktop-only {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }
}

.rs-sidebar.app-sidebar {
  height: calc(var(--app-height) - 6.8rem);
  justify-content: space-between;
  background-color: #0f141f;

  @media (max-width: 900px) {
    display: none !important;
  }
}

.rs-content.main-content {
  height: calc(var(--app-height) - 6.8rem);
  background-color: $color-body-background;
  overflow-y: auto;
  padding: 1.6rem 2.4rem;

  @include desktop-only {
    padding: 3.2rem 4rem 4rem 4rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.sidebar-menu-style {
  background-color: #0f141f;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  color: #808191;
  padding: 2.7rem 0 2.4rem 3.2rem;
}

.rs-nav-item-content {
  background-color: #0f141f;
}

.side-drawer {
  width: 100%;

  .rs-drawer-content {
    width: 250px;
    background-color: #0f141f;
    overflow: scroll;
  }
}

.rs-btn-primary {
  word-break: normal;
  line-break: anywhere;
  white-space: normal;
  font-weight: 600;
  background-color: #6e6af0;
  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  color: #ffffff;

  &:focus {
    background-color: #424090;
  }

  &:not(.rs-btn-disabled):hover {
    background-color: #424090;
  }

  &:not(.rs-btn-disabled):active {
    background-color: #5764ad;
  }
}

//Modal
.rs-modal-wrapper {
  backdrop-filter: blur(5px);

  .rs-modal-body {
    margin-top: 30px;
    padding-bottom: 0px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .rs-modal-content {
    background-color: #0f141f;

    .rs-modal-header .rs-modal-title {
      font-weight: 600;
      font-size: 2rem;
      color: #eef0f7;
    }
  }
}

.rs-input {
  background-color: #0f141f;
  border: 1px solid #343c68;
  color: #ffffff;

  &:hover,
  &:focus,
  &:visited {
    border-color: #6e6af0;
  }
}

//drawer
.rs-drawer-content {
  background-color: #0f141f;
  overflow: scroll;

  .rs-drawer-header .rs-drawer-title {
    font-weight: 600;
    font-size: 2rem;
    color: #eef0f7;
  }
}

//date
.rs-picker-date-menu {
  .rs-picker-toolbar-right-btn-ok {
    background-color: #6e6af0;
  }

  .rs-calendar {
    background-color: #151b29;
  }

  .rs-calendar-table-cell-is-today {
    .rs-calendar-table-cell-content {
      border: 1px solid #6e6af0;
    }
  }

  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #6e6af0;
    border: none;
  }

  .rs-calendar-time-dropdown {
    background-color: #151b29;

    .rs-calendar-time-dropdown-column-title {
      background-color: #6e6af0;
      color: #ffffff;
      font-weight: 700;
      letter-spacing: 0.1px;
    }

    .rs-calendar-time-dropdown-cell-active {
      background-color: #0f141f;
    }

    .rs-calendar-time-dropdown-cell:hover {
      background-color: #6e6af0;
      color: #ffffff;
    }
  }
}

.rs-picker-default .rs-btn {
  background-color: #0f141f !important;
  border-radius: 8px;
  border: 1px solid #343c68;

  .rs-picker-toggle-value {
    color: #ffffff;
  }

  &.rs-picker-toggle.active {
    border-color: #6e6af0 !important;
  }

  &:hover,
  &:focus,
  &:visited {
    border-color: #6e6af0 !important;
  }
}

.select-team-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rs-control-label {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.4rem;

  @include desktop-only {
    font-weight: 500;
    font-size: 1.8rem;
  }
}

//Select
.rs-picker-select-menu {
  .rs-picker-select-menu-item {
    color: #ffffff;

    &.rs-picker-select-menu-item-active,
    &.rs-picker-select-menu-item-disabled &:hover {
      color: #ffffff;
      background-color: #6e6af0;
    }
  }
}

.rs-list-item {
  background-color: #0f141f;
}

.rs-picker-menu {
  background-color: #0f141f;
}

.rs-sidenav-subtle {
  background-color: transparent;
}

//dv-sidebar
.nav-item-wrapper {
  .rs-nav-item {
    margin-bottom: 1.6rem;
    position: relative;

    .rs-nav-item-content {
      display: flex;
      align-items: center;

      svg.nav-icon {
        margin-right: 20px;
        position: absolute;
        left: 20px;
      }

      &.active {
        background-color: #0f141f !important;
        color: #ffffff !important;
        font-size: 1.4rem;
        font-weight: 800;

        &:hover,
        &:active,
        &:focus {
          background: #0f141f !important;
        }

        svg.nav-icon {
          .fill-path {
            fill: #6e6af0;
          }

          .stroke-fill {
            fill: #ffffff;
          }
        }
      }

      &:hover {
        background-color: #0c1019 !important;
        color: #808191;
      }

      .nav-content {
        font-size: 1.4rem;
        margin-left: 1.6rem;
        letter-spacing: 0.3px;
      }
    }
  }
}

//mobile drawer style
.drawer-nav-item-wrapper {
  .rs-nav-item {
    margin-bottom: 1.6rem;
    position: relative;
    display: flex;
    flex-direction: column;

    .rs-nav-item-content {
      display: flex;
      align-items: center;

      &.active {
        background-color: #0f141f !important;
        color: #ffffff !important;
        font-size: 1.4rem;
        font-weight: 800;

        &:hover,
        &:active,
        &:focus {
          background: #0f141f !important;
        }

        svg.drawer-nav-icon {
          .fill-path {
            fill: #6e6af0;
          }

          .stroke-fill {
            fill: #ffffff;
          }
        }
      }

      .drawer-nav-content {
        font-size: 1.4rem;
        margin-left: 1.6rem;
        letter-spacing: 0.3px;
      }
    }
  }
}

//checkBox style
.rs-checkbox-wrapper {
  .rs-checkbox-inner::before {
    border-color: #6e6af0 !important;

    &:hover {
      border-color: #6e6af0 !important;
    }
  }
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before {
  background-color: #6e6af0;
  border: none;
}

// Ghost Button
.rs-btn-ghost {
  word-break: normal;
  line-break: anywhere;
  white-space: normal;
  font-weight: 600;
  padding: 1.2rem 2.4rem !important;
  font-size: 1.4rem;
  color: #ffffff;
}
