.group-wise-table {
  &.rs-table {
    background: #0F141F;

    .rs-table-row {
      border-bottom: 1px solid #272554;
      height: fit-content;
    }

    .rs-table-scrollbar-vertical {
      display: none;
    }

    .rs-table-header-row-wrapper {
      .rs-table-row-header {
        border: none;
        background: #1c2436;

        .rs-table-cell-content {
          background: #6e6af0;
          font-size: 1.4rem;
          color: #ffffff;
          font-weight: 600;

          @include desktop-only {
            font-size: 1.6rem;
          }
        }
      }
    }

    .rs-table-cell {
      font-size: 1.2rem;
      color: #6e6af0;
      font-weight: 500;

      @include desktop-only {
        font-size: 1.4rem;
      }
    }
  }


  .match-status {
    color: rgb(94, 215, 94);
  }

  .disable {
    pointer-events: none;
    color: rgb(94, 215, 94);
  }

  .active {
    cursor: pointer;

    &:hover {
      color: #ffffff;
    }
  }
}