.space-list-page {
  margin: 0;
  margin-bottom: 7rem;

  .space-title {
    font-weight: 600;
    line-height: 40px;
    color: #FFFFFF;
    font-size: 1.8rem;
    margin-bottom: 1.8rem;

    @include desktop-only {
      font-size: 3.2rem;
      margin-bottom: 2.4rem;
    }
  }
}