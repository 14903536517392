@mixin font-style {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 120%;
  text-align: center;
}

.space-detail-page {
  margin: 0 -23px;
  position: relative;
  margin-bottom: 7rem;

  @include desktop-only {
    margin: 0;
  }

  .post-heading-filter {
    display: none;

    @include desktop-only {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5.4rem;
      margin-bottom: 1.6rem;
    }

    .posts {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 120%;
      color: #FFFFFF;
    }

    .filter-button-container {
      border: 1px solid #1E233D;
      border-radius: 5px;
      background-color: transparent !important;
      padding: .8rem 1.6rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      .filter-text {
        font-weight: 500;
        font-size: 1.2rem;
        color: #FFFFFF;
        margin-right: 8px;
      }
    }
  }

  .tabs-wrapper {
    margin-top: 2.4rem;

    &.rs-nav.rs-nav-horizontal {
      display: flex;
      justify-content: space-around;


      ul {
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        .rs-nav-item {
          width: 15rem;
          width: 100%;

          .rs-nav-item-content {
            font-size: 11px;
            background-color: transparent;
            padding: 1.2rem 0;
            font-weight: 500;
            text-align: center;
            color: #62677c;

            @include desktop-only {
              line-height: 120%;
              font-size: 1.8rem;
            }
          }

          &.rs-nav-item-active {
            .rs-nav-item-content {
              color: #FFFFFF;
              background-color: #1C2139;
              font-weight: 500;
              text-align: center;
              font-size: 11px;

              &::before {
                background-color: #6E6AF0;
              }

              @include desktop-only {
                line-height: 120%;
                font-size: 1.8rem;
              }
            }
          }
        }
      }

      .rs-nav-waterline {
        border: 1px solid #1E233D;
      }
    }
  }


  .post-card-grid {
    padding: 0 2.4rem;
    margin-top: 2.4rem;

    @include desktop-only {
      padding: 0;
    }

    .no-post-text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20rem;
      font-size: larger;
      color: #FFFFFF;
    }

    .mv-create-post {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 2px dashed #1E233D;
      border-radius: 1.2rem;
      padding: 3rem 0 2.5rem 0;
      margin-bottom: 1.6rem;

      @include desktop-only {
        display: none;
      }

      .create-post-text {
        margin-top: 8px;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 120%;
        color: #414B82;
      }
    }
  }

  .float-filter-button {
    cursor: pointer;
    position: fixed;
    bottom: 40px;
    right: 17px;
    z-index: 100;
    display: flex;
    transition: all 0.4s ease-out;
    width: 6rem;
    height: 6rem;
    background: #6E6AF0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop-only {
      display: none;
    }
  }
}