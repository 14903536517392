.short-detail-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 0 auto;
  overflow: unset;

  @include desktop-only {
    overflow: hidden;
    height: 100%;
    width: 100%;
    flex-direction: row;
  }

  .short-detail {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .v-divider {
    display: none;

    @include desktop-only {
      display: block;
      height: auto;
      border-left: 1px solid #2D3754;
    }
  }

  .short-comment {
    width: 100%;
    position: relative;
    margin-top: 3.2rem;
    overflow-y: auto;


    @include desktop-only {
      margin-top: 0;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}