 .grid-style {
   padding: 0;

   .in-game-name {
     font-weight: 600;
     font-size: 1.4rem;
     line-height: 120%;
     color: #FFFFFF;
     margin: 0.8rem 0;

     @include desktop-only {
       font-size: 1.8rem;
       font-weight: 500;
     }
   }

   .member-result-input-style {
     color: #343C68;

     &.rs-input {
       background-color: #0F141F;
       border: 1px solid #808191;
     }
   }
 }