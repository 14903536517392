.team-result-details-wrapper {

  &::-webkit-scrollbar {
    display: none !important;
  }

  .hr-line {
    border: 1px solid #272D4E;
  }

  .sub-heading {
    font-weight: 600;
    font-size: 2rem;
    line-height: 23px;
    color: #CBCFE6;
    padding: 1.6rem 0;
  }

  .team-score-table {
    ::-webkit-scrollbar {
      display: none;
    }

    &.rs-table {
      background: transparent;

      .rs-table-scrollbar-vertical {
        display: none;
      }

      .rs-table-scrollbar-horizontal {
        display: none;
      }


      .rs-table-header-row-wrapper {
        .rs-table-row {
          border: none;

          &.rs-table-row-header {
            background: #0F141F;

            .rs-table-cell {
              background: #0F141F;
              font-weight: 500;
              color: #CBCFE6;
              font-size: 1.6rem;
              overflow: auto;

              @media (max-width:540px) {
                font-size: 1.4rem;
              }
            }
          }
        }

      }

      .rs-table-body-row-wrapper {
        overflow: auto;

        .rs-table-row {
          border: none;
          background: #0F141F;

          .rs-table-scrollbar-vertical {
            display: none;
          }

          .rs-table-cell-group {
            background: transparent;

            .rs-table-cell {
              background-color: transparent;
              font-weight: 500;
              font-size: 16px;
              color: #6E6AF0;
              text-transform: capitalize;

              @media (max-width:540px) {
                font-size: 1.4rem;
                font-weight: 400;
              }
            }

          }
        }
      }
    }
  }

  .total-score-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.2rem;

    .total-score-heading {
      font-weight: 600;
      font-size: 1.6rem;
      color: #EEF0F7;
    }

    .total-score-value {
      color: #6E6AF0;
    }
  }
}