.super-mod {
  border: 1px solid #E33342;
  border-radius: 12px;

  @media (max-width:900px) {
    border: none;
  }
}

.play-off-detail-card-container {
  background-color: #0F141F;
  border-radius: 1.2rem;
  position: relative;


  .super-mod-icon {
    position: absolute;
    right: 4rem;
  }


  .play-off-detail-card-wrapper {
    padding: 1.6rem 2rem;
    display: flex;
    width: 100%;

    @media (max-width:900px) {
      flex-direction: column;
      padding: 0;
    }


    .game-tournamentImage-container {
      width: 25.6rem;
      height: 25rem;
      border-radius: 1.2rem;
      overflow: hidden;
      flex: 0 0 auto;

      @media (max-width:900px) {
        width: 100%;
        border-radius: 0;
      }

      .game-tournament-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .play-off-detail-container {
      margin-left: 2.4rem;
      width: 100%;
      margin-top: 8px;

      @media (max-width:900px) {
        margin-left: 0;
        margin-top: 1.6rem;
        padding: 0 2.4rem;

      }

      .play-off-tournament-name {
        font-weight: 600;
        line-height: 120%;
        color: #FFFFFF;
        font-size: 1.4rem;
        margin-bottom: 1.6rem;

        @include desktop-only {
          margin-bottom: 3.2rem;
          font-size: 2.7rem;

        }
      }

      .play-off-tournament-detail {
        margin-top: 3.2rem;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 2.4rem;

        @media (max-width:1200px) {
          grid-template-columns: repeat(2, 1fr);
          column-gap: .5rem;
        }

        @media (max-width:900px) {
          display: flex;
          flex-direction: column;
          padding: 0;
        }

        .tournament-detail-info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;

          @include desktop-only {
            flex-direction: column;

            margin-bottom: 4.2rem;
          }

          .info-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #808191;
            margin-bottom: 0;

            @include desktop-only {
              margin-bottom: 8px;
            }
          }

          .info-value-text {
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 19px;
            color: #FFFFFF;
          }

          .complete-style {
            background: #4CAF50;
            border-radius: 2px;
            text-align: center;
            padding: 4px 8px;
            width: 7.6rem;
            font-weight: 600;
            font-size: 10px;
            color: #FFFFFF;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}