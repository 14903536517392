.shorts-card {
  width: 100%;
  margin-bottom: 1.2rem;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid transparent;
  position: relative;

  @include desktop-only {
    &:hover {
      border: 1px solid #6e6af0;
    }
  }


  .shorts-preview-wrapper {
    cursor: pointer;
    height: 18rem;

    .shorts-preview {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .published-details {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(10px);
    position: absolute;
    width: 100%;
    top: 0;
    transition: all 0.3s ease-in-out;

    .publish {
      color: #c3db5d;
      font-size: 1.5rem;
      font-weight: 700;
    }

    .date {
      font-size: 1.4rem;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .short-delete {
    width: 100%;
    font-size: 1.6rem;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    padding: 1rem;
    background-color: #cb414f;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .delete-icon {
      margin-left: 1rem;
    }
  }
}

.disable {
  pointer-events: none;
}