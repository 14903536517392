.comment-mention-wrapper {
  background-color: aliceblue;
  padding: .5rem 1rem;
  display: flex;
  align-items: center;
  color: #000000;
  width: fit-content;
  border-radius: 1rem;

  .close-icon {
    cursor: pointer;
    margin-right: 5px;
  }
}