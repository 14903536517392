.rs-row.playoff-container {
  .rs-col {
    margin-bottom: 2rem;
  }
}

.playoff-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;


  .schedule-btn {
    padding: 1rem;
    font-size: 1rem;

    @include desktop-only {
      font-size: 1.4rem;
    }
  }

  .playoff-grp-title {
    font-size: 1.8rem;
    color: #FFFFFF;
    font-weight: 600;

    @include desktop-only {
      font-size: 3.2rem;
      line-height: 40px;
      margin-left: 5px;
    }
  }
}