.short-title-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;

  .shorts-btn {
    padding: 0.8rem;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 1.2rem 1.6rem;

    @include desktop-only {
      padding: 1.2rem 2.4rem;
      font-size: 1.4rem;
    }
  }

  .shorts-title {
    font-size: 1.8rem;
    color: #FFFFFF;
    font-weight: 600;

    @include desktop-only {
      font-size: 3.2rem;
      line-height: 40px;
    }
  }
}

.intermediate-state-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.4rem;
  justify-content: center;
  height: calc(100vh - 61rem);

  @include desktop-only {
    height: auto;
    justify-content: flex-start;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .state-buffer {
      display: inline-block;
      width: 40px;
      height: 40px;

      &::after {
        content: " ";
        display: block;
        width: 32px;
        height: 32px;
        margin: 0;
        border-radius: 50%;
        border: 4px solid #fff;
        border-color: rgba(110, 106, 240, 1) transparent rgba(110, 106, 240, 1) transparent;
        animation: buffer 1.2s linear infinite;
      }
    }

    .state-text {
      font-size: 2rem;
      margin-left: 1rem;
      color: rgba(110, 106, 240, 1)
    }
  }

  .by-line {
    font-size: 1.4rem;
    margin-top: 1.6rem;
    color: #FFFFFF
  }

  .view-btn {
    font-size: 1.4rem;
    margin-top: 2rem;
  }
}

@keyframes buffer {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}