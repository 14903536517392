.space-filter-container {

  .post-title-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    margin-bottom: 1.6rem;
  }

  .filter-clear-btn {
    display: flex !important;
    justify-content: space-between;
    align-items: center;


    .filter-clear-text {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 120%;
      color: #FFFFFF;
    }

    .filter-clear-button {
      cursor: pointer;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 120%;
      color: #FFFFFF;
      background: rgba(132, 140, 162, 0.2);
      border-radius: 6px;
      padding: 6px 1.2rem;
    }
  }

  .hr-line {
    border: 1px solid #272D4E;
    margin-top: 1.6rem;
    margin-bottom: 2.4rem;
  }

  .menu {
    display: block;
    margin-bottom: 2.6rem;

    &.rs-radio-group-inline {
      margin-left: 0;
    }

    .radio-button-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .radio-label {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 120%;
        color: #FFFFFF;
      }
    }
  }

  .apply-btn {
    display: inline;
  }
}